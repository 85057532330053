.App {
  text-align: center;
}

canvas {
    width: 100%;
    height: auto;
}

@media only screen and (min-width: 640px) {
  div.video-container {
    width: 640px;
    display: inline-block;
  }
}
